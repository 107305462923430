import React from 'react'
import Layout from '../components/Layout'
import SmallHeader from '../components/SmallHeader'
import Icon from '../components/Icon'
import Footer from '../components/Footer'
import data from '../data/pricing.json'

const PricingPage = () => (
  <Layout path="/pricing" title="Pricing - Brentiv - Rental Software">
    <div className="pricing-page">
      <SmallHeader>
        <div className="container">
          <div className="row">
            <div className="col-md-8 ml-auto mr-auto text-center">
              <h1 className="title">Simple pricing for everyone</h1>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 text-center">
              <h2 className="subtitle">
                The lowest price in the market. No hidden charges. Just smile
                and spend more on renewing your products, not on monthly fees.
              </h2>
            </div>
          </div>
        </div>
      </SmallHeader>
      <div className="container plan-container">
        <div className="row">
          <div className="col-md-8 col-lg-6 ml-auto mr-auto">
            <div className="plan">
              <div className="plan-header">
                <div className="plan-price">
                  <span className="currency">$</span>
                  <span className="amount">19</span>
                  <span className="help-text">/user/month</span>
                </div>
              </div>
              <div className="plan-body">
                <ul className="list-unstyled plan-features">
                  <li className="feature">
                    <Icon className="feature-icon" name="clipboard" /> Unlimited
                    orders
                  </li>
                  <li className="feature">
                    <Icon className="feature-icon" name="shopping-cart" />{' '}
                    Unlimited products
                  </li>
                  <li className="feature">
                    <Icon className="feature-icon" name="users" /> Unlimited
                    customers
                  </li>
                  <li className="feature">
                    <Icon className="feature-icon" name="map" /> Unlimited
                    locations
                  </li>
                </ul>
                <a
                  className="btn btn-plan btn-block btn-success"
                  href="https://signup.brentiv.com"
                >
                  Try for free
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container faq-container">
        <div className="row">
          <div className="col-12 text-center">
            <h2 className="title">Frequently Asked Questions</h2>
          </div>
        </div>
        <div className="row">
          {data.faqs.map((faq, index) => (
            <div key={index} className="col-sm-6 faq-item">
              <h3 className="faq-title">{faq.question}</h3>
              <p
                className="faq-description"
                dangerouslySetInnerHTML={{ __html: faq.answer }}
              />
            </div>
          ))}
        </div>
      </div>
      <Footer withContact />
    </div>
  </Layout>
)

export default PricingPage
